import { RouteComponentProps } from '@reach/router';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Helmet } from 'react-helmet';
import { staticStyles } from 'styles';

import { Container } from '../components/Container';

const Impresum: React.FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Helmet
        title={`Wienerberger - ${formatMessage({ id: 'shared.impressum' })}`}
      />
      <Container className={staticStyles.staticWrapper}>
        <h1 css={staticStyles.title}>
          <FormattedMessage id="shared.impressum" />
        </h1>
        <h2 css={staticStyles.subtitle}>
          <FormattedMessage id="impressum.subtitle" />
        </h2>

        <div css={staticStyles.noPaddingText}>
          <p css={staticStyles.boldText}>
            <FormattedMessage id="impressum.company" />{' '}
            <span css={staticStyles.text}>
              <FormattedMessage id="impressum.company.name" />
            </span>
          </p>

          <p css={staticStyles.boldText}>
            <FormattedMessage id="impressum.subtitle.short-name" />{' '}
            <span css={staticStyles.text}>Wienerberger d.o.o.</span>
          </p>

          <p css={staticStyles.boldText}>
            <FormattedMessage id="impressum.company.headquarters" />{' '}
            <span css={staticStyles.text}>
              <FormattedMessage id="impressum.company.headquarters.address" />
            </span>
          </p>

          <p css={staticStyles.boldText}>
            <FormattedMessage id="impressum.company.central-office" />{' '}
            <span css={staticStyles.text}>
              <FormattedMessage id="impressum.company.central-office.address" />
            </span>{' '}
          </p>

          <p css={staticStyles.boldText}>
            <FormattedMessage id="impressum.company.id" />{' '}
            <span css={staticStyles.text}>3122077</span>
          </p>

          <p css={staticStyles.boldText}>
            <FormattedMessage id="impressum.company.personal-id" />{' '}
            <span css={staticStyles.text}>83508016262</span>
          </p>
        </div>

        <p css={staticStyles.boldText}>
          <FormattedMessage id="impressum.company.court-register" />{' '}
          <span css={staticStyles.text}>020002278</span>
        </p>

        <div css={staticStyles.noPaddingText}>
          <p css={staticStyles.boldText}>
            <FormattedMessage id="impressum.company.bank" />
          </p>
          <p css={staticStyles.text}>
            <FormattedMessage id="impressum.company.bank.name.1" />
          </p>
          <p css={staticStyles.text}>
            <FormattedMessage id="impressum.company.bank.iban.1" />
          </p>
        </div>
        <br />
        <div css={staticStyles.noPaddingText}>
          <p css={staticStyles.text}>
            <FormattedMessage id="impressum.company.bank.name.2" />
          </p>
          <p css={staticStyles.text}>
            <FormattedMessage id="impressum.company.bank.iban.2" />
          </p>
        </div>
        <br />
        <h2 css={staticStyles.subtitle}>
          <FormattedMessage id="impressum.company.administration" />
        </h2>
        <p css={staticStyles.text}>
          <FormattedMessage id="impressum.company.administration.members" />
        </p>
        <div css={staticStyles.noPaddingText}>
          <p css={staticStyles.boldText}>Tin Ban</p>
          <p css={staticStyles.boldText}>Tomislav Franko</p>
          <p css={staticStyles.boldText}>Marko Šustić</p>
        </div>
      </Container>
    </>
  );
};

export default Impresum;
